import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable, map } from 'rxjs';
import { ApiResponse } from 'sumax-erp-schematics';
import { TbGrupoConcepto } from '~models/maestros-sync/maestros/TbGrupoConcepto';

@Injectable({
	providedIn: 'root',
})
export class TbGrupoConceptoService {
	private readonly _url = `${environment.HOST_MAESTROS_SYNC}/tbgrupoconceptos`;

	constructor(private readonly _http: HttpClient) {}

	findAllByIdSistemaList(idSistema: number[]): Observable<TbGrupoConcepto[]> {
		return this._http.post<ApiResponse<TbGrupoConcepto[]>>(`${this._url}/findAllByIdList`, idSistema).pipe(map((resp) => resp.data));
	}
	findAllByIdList(idGrupoConcepto: number[]): Observable<TbGrupoConcepto[]> {
		return this._http.post<ApiResponse<TbGrupoConcepto[]>>(`${this._url}/findAllByIdGrupoConceptoList`, idGrupoConcepto).pipe(map((resp) => resp.data));
	}
}
