/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { map, mergeMap, toArray } from 'rxjs/operators';
import { ApiResponse } from '~shared/interface/ApiResponse';
import { Observable } from 'rxjs';
import { CustomHttpClientService } from '~shared/services/custom-http-client.service';
import { TbConfiguracionAsignacionListado } from '~models/maestros-sync/maestros';
import { NgSelectOption } from '~shared/interface/ngselect.interfaces';
import { TbTipoEnvio, TbTipoOperador, TbUnidadMedida, TbViaTransporte } from '~models/maestros';
import { TbconfiguracionAsignacionDto } from '../../../shared/models/maestros-sync/maestros/TbconfiguracionAsignacionDto';

const url = `${environment.HOST_MAESTROS_SYNC}/tbconfiguracionasignaciones`;

@Injectable({
	providedIn: 'root',
})
export class TbConfiguracionAsignacionService {
	private readonly _COD_SISTEMA = environment.COD_SISTEMA;
	constructor(protected http: CustomHttpClientService) {}
	idSistema = 28;

	findAllComprobantesViewBySistema(codigo: string): Observable<NgSelectOption<TbConfiguracionAsignacionListado>[]> {
		return this.http.get<ApiResponse<TbConfiguracionAsignacionListado>>(`${url}/findByIdSistemaAndCodigo/${this.idSistema}/${codigo}`).pipe(
			map((res) => res.data.tbConfiguracionAsignacionDetalles),
			// eslint-disable-next-line @typescript-eslint/no-unsafe-argument
			mergeMap((data) => {
				if (data) {
					return data.map((res: any) => {
						return {
							...res,
							value: res.idTipoOperador,
							label: `${res.codigo} - ${res.nombre}`,
						};
					});
				}
				return [];
			}),

			toArray()
		);
	}

	findAllViaTransporteByCodigoSistema(codigo: string): Observable<NgSelectOption<TbViaTransporte>[]> {
		return this.http.get<ApiResponse<TbconfiguracionAsignacionDto>>(`${url}/findByCodigoSistemaAndCodigo/${this._COD_SISTEMA}/${codigo}`).pipe(
			map((res) => res.data.tbConfiguracionAsignacionDetalles as TbViaTransporte[]),
			mergeMap((data) =>
				data.map((res: TbViaTransporte) => {
					return {
						...res,
						value: res.idViaTransporte,
						label: `${res.codigo} - ${res.nombre}`,
					};
				})
			),
			toArray()
		);
	}

	findAllTipoOperadorByCodigoSistema(codigo: string): Observable<NgSelectOption<TbTipoOperador>[]> {
		return this.http.get<ApiResponse<TbconfiguracionAsignacionDto>>(`${url}/findByCodigoSistemaAndCodigo/${this._COD_SISTEMA}/${codigo}`).pipe(
			map((res) => res.data.tbConfiguracionAsignacionDetalles as TbTipoOperador[]),
			mergeMap((data) =>
				data.map((res: TbTipoOperador) => {
					return {
						...res,
						value: res.idTipoOperador,
						label: `${res.codigo} - ${res.nombre}`,
					};
				})
			),
			toArray()
		);
	}

	findAllUnidadMedidaByCodigoSistema(codigo: string): Observable<NgSelectOption<TbUnidadMedida>[]> {
		return this.http.get<ApiResponse<TbconfiguracionAsignacionDto>>(`${url}/findByCodigoSistemaAndCodigo/${this._COD_SISTEMA}/${codigo}`).pipe(
			map((res) => res.data.tbConfiguracionAsignacionDetalles as TbUnidadMedida[]),
			mergeMap((data) =>
				data.map((res: TbUnidadMedida) => {
					return {
						...res,
						value: res.idUnidadMedida,
						label: `${res.codigo} - ${res.nombre}`,
					};
				})
			),
			toArray()
		);
	}

	findAllTbEnvioaByCodigoSistema(codigo: string): Observable<NgSelectOption<TbTipoEnvio>[]> {
		return this.http.get<ApiResponse<TbconfiguracionAsignacionDto>>(`${url}/findByCodigoSistemaAndCodigo/${this._COD_SISTEMA}/${codigo}`).pipe(
			map((res) => res.data.tbConfiguracionAsignacionDetalles as TbTipoEnvio[]),
			mergeMap((data) =>
				data.map((res: TbTipoEnvio) => {
					return {
						...res,
						value: res.idTipoEnvio,
						label: `${res.codigo} - ${res.nombre}`,
					};
				})
			),
			toArray()
		);
	}

	findAllCustom(codigo: string): Observable<NgSelectOption<unknown>[]> {
		return this.http.get<ApiResponse<TbconfiguracionAsignacionDto>>(`${url}/findByCodigoSistemaAndCodigo/${this._COD_SISTEMA}/${codigo}`).pipe(
			map((res) => res.data.tbConfiguracionAsignacionDetalles ?? []),
			mergeMap((data) =>
				data.map((res) => {
					return {
						...res,
						value: Object.entries(res).find(([key]) => key.startsWith('id'))?.[1],
						label: `${res.codigo} - ${res.nombre}`,
					};
				})
			),
			toArray()
		);
	}
}
